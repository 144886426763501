@media screen and (max-width:430px) {

    p,
    a,
    span,
    li,
    button.btn.btn-yellow {
        font-size: 16px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 20px !important;
        text-align: center !important;
    }

    h5 {
        font-size: 18px !important;
    }

    .navbar-section .navbar a {
        font-size: 14px !important;
    }

    .critical-for-section .mbl-view-critical-for,
    .critical-for-section .mbl-view-critical-for p {
        display: flex;
        text-align: left !important;
        align-items: center !important;
        gap: 15px;
    }

    .navbar-section .navbar-brand img,
    .navbar-section a.navbar-brand {
        max-width: 75% !important;
        height: auto;
        margin-right: 0 !important;
    }

    .registerNow-section p {
        text-align: center;
    }

    .registerNow-section .btn-div {
        text-align: center;
    }

    .slider-section img {
        margin: auto;
    }

    .slider-section {
        padding: unset;
    }

    .slider-section .card {
        background-color: transparent !important;
        box-shadow: unset !important
    }
}

@media screen and (min-width:768px) {
    .digidoc-unique-section .card {
        height: 320px;
    }

    .banner-content {
        padding-left: 25px;
    }

    .slider-section {
        padding: 30px;
    }

    .slider-section .card {
        background-color: transparent !important;
        box-shadow: 0px -0.5px 2px 0.5px rgb(212 201 201 / 50%) !important
    }
}

@media screen and (max-width:768px) {
    .navbar-section .navbar-text {
        display: none;
    }

    .navbar-section,
    .navbar-section button.btn.btn-yellow {
        padding: .5rem 1rem !important;
        text-wrap: nowrap !important;
    }

    .navbar-section .navbar-nav {
        gap: unset !important;
    }

    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 8px !important;
        color: var(--slick-dots-color) !important;
        opacity: 1.75 !important;
    }

    .homeBanner-section .animation-main-div {
        display: none;
    }

}

@media screen and (max-width:900px) {

    .homeBanner-section .banner-content,
    .banner-content {
        flex-direction: column-reverse;
    }

    h3,
    h4,
    p,
    a,
    .homeBanner-section .banner-content .img-div .logo-div,
    .homeBanner-section .banner-content .btn-div {
        text-align: center !important;
        justify-content: center;
    }

    .digidoc-features-section .thumbnail-heading p {
        color: var(--text-black);
        font-weight: 600;
        border-radius: 24px;
        padding: 7px 25px;
        cursor: pointer;
        box-shadow: -1px 6px 2.3px -2px #0000001C;
        text-wrap: nowrap;

    }

    .left-arrow,
    .slick-prev:hover,
    .slick-prev {
        position: relative;
        left: 5px;
        top: 40px;
        width: 20px;
        height: 20px;
        background-color: var(--secondary-color);
        border-radius: 50%;
        padding: 5px;
    }

    .right-arrow,
    .slick-next:hover,
    .slick-next {
        position: relative;
        left: 90%;
        bottom: 35px;
        width: 20px;
        height: 20px;
        background-color: var(--secondary-color);
        border-radius: 50%;
        padding: 5px;
    }

}

@media screen and (min-width:900px) {

    .digidoc-features-section .slider-for p {
        text-align: center;
    }

    .digidoc-features-section .slider-nav p {
        text-align: left;
        border-radius: 8px;
        padding: 6px 12px;
        cursor: pointer;
        color: var(--text-ash);
    }

    .digidoc-features-section .thumbnail-heading p {
        background-color: var(--secondary-color) !important;
        color: var(--text-black);
        font-weight: 600;

    }

    .digidoc-features-section .slider-nav .thumbnail .card {
        background-color: var(--background-color);
    }

}

@media screen and (max-width:1250px) {

    .background-Img {
        background-image: none;
    }
}