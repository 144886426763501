body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Avenir-Light";
  src: url(/src/assets/fonts/Avenir_LT_Std/AvenirLTStd35Light/AvenirLTStd35Light.otf);
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Bold";
  src: url(/src/assets/fonts/Avenir_LT_Std/AvenirLTStd85Heavy/AvenirLTStd85Heavy.otf);
  font-style: normal;
}


html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
