:root {
  --primary-color: #333333;
  --secondary-color: #FBB03B;
  --tertiary-color: #9D702B;
  --background-white: #FFFFFF;
  --background-color: #F9F9F9;
  --text-ash: #B5B5B5;
  --text-light-black: #505050;
  --text-black: #000000;
  --slick-dots-color: #E3E3E3;
  --color-yellow: #F8B246;
  --input-bg-color: #E2E2E2;
  --placeholder-color: #959595;
  --color-red: #F42525;
  --astrik-color: #B00505;
}

/* common css */
p,
a {
  font-size: 18px;
  font-family: 'Avenir-Light' !important;
  color: var(--text-black);
  font-weight: 400;
  text-align: left;
}

h3,
h4,
h5 {
  font-family: 'Avenir-Bold' !important;
  color: var(--tertiary-color) !important;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 1rem !important;
}

h3 {
  font-size: 32px !important;
}

h4 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;
}

.thankYou-div a {
  color: var(--tertiary-color) !important;
}

.text-left {
  text-align: left;
}

.mxy-40 {
  margin: 40px 0;
}

.pxy-40 {
  padding: 40px 0;
}

.pxy-80 {
  padding: 80px 0 0 0;
}

.my-20 {
  margin-bottom: 20px !important;
}

.mxy-20 {
  margin: 20px 0 !important;
}

.py-20 {
  padding: 20px 0 !important;
}

.gap-20 {
  gap: 20px;
}

.gap-5px {
  gap: 5px !important;
}

.background-clr {
  background-color: var(--background-color);
  padding: 15px 0;
}


hr {
  margin: .5rem 0 !important;
  border-top: .5px solid var(--text-black) !important;
  opacity: .5 !important;
}

button.btn.btn-yellow {
  font-size: 16px;
  font-family: 'Avenir-Light' !important;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--text-black);
  border: var(--secondary-color);
  border-radius: 6px;
  padding: 7px 10px;
  transition: all 0.3s;
}

button.btn.btn-yellow:active {
  background-color: var(--secondary-color);
  transform: scale(0.98);
}

.card {
  border-radius: 20px !important;
  border: unset !important;
  padding: 10px 20px;

}

.row-25>* {
  margin-bottom: 25px;
}

.img-div {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: auto;
  text-align: center;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

.listed-points-div,
.listed-points-div ul {
  width: fit-content;
  margin: auto;
  list-style-type: none;
}

.listed-points-div li {
  margin-bottom: 3px;
  font-family: 'Avenir-Light' !important;
}

.error-message {
  text-align: left !important;
  font-size: 14px;
  color: var(--astrik-color);
}

/* common css ends*/


/* navbar section css starts */
.navbar-section {
  padding: .5rem 3rem !important;
  background-color: var(--background-white);
  /* height: 60px; */
}

.navbar-section .navbar-nav a.nav-link {
  font-size: 16px;
  font-weight: 600 !important;
  color: var(--text-black);
  font-family: 'Avenir-Bold' !important;
}

.navbar-section .navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-section span,
span {
  font-size: 16px;
  font-weight: 600;
  color: var(--tertiary-color);
  text-decoration: underline;
  font-family: 'Avenir-Light' !important;
}

.navbar-section .navbar-nav .nav-link.active {
  font-weight: 700 !important;
  color: var(--secondary-color);
}

/* navbar section css ends */

/* home banner section css starts */
.homeBanner-section p {
  font-size: 20px;
}

.homeBanner-section #image-left,
.homeBanner-section #image-right,
.homeBanner-section #image-center {
  position: relative;
  transition: all 0.5s ease-in-out;
}

.homeBanner-section #image-left {
  left: 100px;
}

.homeBanner-section #image-center {
  z-index: 2;
}

.homeBanner-section #image-right {
  right: 100px;
}

.homeBanner-section .move-in #image-left {
  left: 20px;
}

.homeBanner-section .move-in #image-right {
  right: 20px;
}

.animation-main-div {
  position: relative;
  left: 92%;
  top: 80px;
}

.animation-main-div .circle,
.animation-main-div .circle2,
.animation-main-div .circle3 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--secondary-color);
  position: absolute;
  animation: scaler 5s infinite;
  animation-timing-function: linear;
}

.animation-main-div .circle2 {
  animation-delay: 1s;
}

.animation-main-div .circle3 {
  animation-delay: 2s;
}

@keyframes scaler {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(5);
    opacity: 0.20;
  }

  67% {
    transform: scale(7);
    opacity: 0.10;
  }

  100% {
    transform: scale(9);
    opacity: 0;
  }
}

.pause-animation .circle,
.pause-animation .circle2,
.pause-animation .circle3 {
  animation-play-state: paused;
}

/* home banner section css starts */


/* slider section css starts */
.slick-dots li {
  width: 8px !important;
  height: 0px !important;
  margin: 0 3px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1.75 !important;
  color: var(--secondary-color) !important;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 8px !important;
  color: var(--background-white) !important;
  opacity: 1.75 !important;
}

/* slider section css ends */

/* about us section css starts */
.background-Img {
  background-image: url('../src/assets/images/png/ellipse_background_img.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

/* about us section css ends */


/* register now section css starts */
.registerNow-section {
  border-radius: 20px !important;
  border: unset !important;
  padding: 10px 20px;
}

/* register now section css ends */

/* footer section css starts */
.footer-section p {
  margin-bottom: 0px;
}

.footer-section .enquire-setion {
  margin: 25px 0;
}

span.copyright-text,
span.copyright-text span {
  color: var(--text-light-black);
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  font-family: 'Avenir-Light' !important;
}

.footer-section {
  /* padding: 20px; */
  color: #000;
}

.footer-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.links {
  display: flex;
  gap: 20px; /* Adds space between the links */
}

.footer-link {
  text-decoration: none;
  color: #000;
  font-size: 18px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #fbb03b; 
}

.social-icons {
  display: flex;
  gap: 15px; /* Adds space between the icons */
}

.social-icons i {
  font-size: 24px;
  color: #000;
  transition: color 0.3s;
}

.social-icons i:hover {
  color: #fbb03b; 
}

.enquire-section {
  text-align: center;
  margin-top: 20px;
}

.enquire-section p {
  margin: 10px 0;
}


/* footer section css ends */

/* contact us form modal css starts */

.contactUs-form h4,
.book-demoModal-form h4 {
  color: var(--color-yellow) !important;
  font-family: 'Avenir-Light' !important;
}

.contactUs-form h5,
.book-demoModal-form h5 {
  color: var(--text-black) !important;
  font-family: 'Avenir-Light' !important;
}

.contactUs-form label,
.book-demoModal-form label {
  color: var(--tertiary-color) !important;
  margin-bottom: 5px;
}

.contactUs-form input,
.book-demoModal-form input,
.book-demoModal-form select,
.book-demoModal-form select:focus,
.book-demoModal-form select:focus-visible,
.contactUs-form input:focus,
.book-demoModal-form input:focus,
.book-demoModal-form input:focus-visible,
.contactUs-form input:focus-visible {
  background-color: var(--input-bg-color);
  border-radius: 10px;
  border: unset;
  box-shadow: unset;
}

.form-select,
input.preferred-time {
  font-size: 14px !important;
  width: 210px !important;
}

.form-select option:disabled {
  color: var(--placeholder-color) !important;
}

.contactUs-form input::placeholder,
.book-demoModal-form input::placeholder {
  color: var(--placeholder-color);
  font-size: 14px;
}

.contactUs-form button.btn.btn-yellow,
.book-demoModal-form button.btn.btn-yellow {
  width: 100%;
}

.contactUs-form span,
.book-demoModal-form span {
  text-decoration: none;
  color: var(--astrik-color);
}

.thankYou-div p {
  font-size: 16px;
}

.modal-header {
  border-bottom: unset !important;
  padding-bottom: 0 !important;
}

.modal-header img {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

/* contact us form modal css ends */


/* gif css */
.static {
  position: absolute;
  background: white;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.static:hover {
  opacity: 0;
}

/* gif css ends */

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

/* Target only the slider with the specific class */
.custom-fade-slider .slick-slide {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: none; /* Disable default transition to use animations */
}

.custom-fade-slider .slick-slide.slick-active {
  animation: fadeIn 0.5s ease-in forwards; /* Fade-in speed */
  opacity: 1;
  z-index: 2; /* Keep the active slide on top */
}

.custom-fade-slider .slick-slide:not(.slick-active) {
  animation: fadeOut 0.5s ease-out forwards; /* Fade-out speed */
  z-index: 1;
}

/* Keyframes for fade-in and fade-out animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
